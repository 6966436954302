export const BookingSummary = [
	{
		id: 1,
		user: {
            username: "Akshay Sunilkumar",
            email: "akshay@gmail.com",
            mobile: "94476949099",
            remarks: "Love is a complex and multifaceted emotion, often described as a deep feeling of affection, attachment, and care towards someone or something. It encompasses a range of emotions, including compassion, empathy, warmth, and devotion. Love can manifest in various forms, such as romantic love between partners, familial love between family members, platonic love between friends, and even love for hobbies, interests, or ideals. It involves a deep emotional connection, understanding, and acceptance of another person, often accompanied by a desire for their well-being and happiness.",
		},
	},
	{
		id: 2,
		user: {
            username: "Jane Smith",
            email: "smith@gmail.com",
            mobile: "94476949099",
            remarks: "Love is a complex and multifaceted emotion, often described as a deep feeling of affection, attachment, and care towards someone or something. It encompasses a range of emotions, including compassion, empathy, warmth, and devotion. Love can manifest in various forms, such as romantic love between partners, familial love between family members, platonic love between friends, and even love for hobbies, interests, or ideals. It involves a deep emotional connection, understanding, and acceptance of another person, often accompanied by a desire for their well-being and happiness.",
		},
	},
	{
		id: 3,
		user: {
            username: "Don Smith",
            email: "don@gmail.com",
            mobile: "94476949099",
            remarks: "Love is a complex and multifaceted emotion, often described as a deep feeling of affection, attachment, and care towards someone or something. It encompasses a range of emotions, including compassion, empathy, warmth, and devotion. Love can manifest in various forms, such as romantic love between partners, familial love between family members, platonic love between friends, and even love for hobbies, interests, or ideals. It involves a deep emotional connection, understanding, and acceptance of another person, often accompanied by a desire for their well-being and happiness.",
		},
	},
	{
		id: 4,
		user: {
            username: "Thamanna Bhattiya",
            email: "thamanna@gmail.com",
            mobile: "94476949099",
            remarks: "Love is a complex and multifaceted emotion, often described as a deep feeling of affection, attachment, and care towards someone or something. It encompasses a range of emotions, including compassion, empathy, warmth, and devotion. Love can manifest in various forms, such as romantic love between partners, familial love between family members, platonic love between friends, and even love for hobbies, interests, or ideals. It involves a deep emotional connection, understanding, and acceptance of another person, often accompanied by a desire for their well-being and happiness.",
		},
	},
];
